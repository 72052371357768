<template>
    <div class="d-flex flex-column flex-root">
        <div v-if="loading" class="position-relative" style="height:400px;">
            <spinner bg-transparent />
        </div>
        <div v-else-if="detailsData" class="d-flex flex-column-fluid">
            <div :class="{ 'container-fluid': contentFluid, container: !contentFluid }">
                <b-card class="card-custom">
                    <b-row>
                        <b-col md="6" lg="">
                            <div class="d-md-flex">
                                <div class="flex-shrink-0 mb-5 mb-md-0 mr-md-7">
                                    <cs-avatar classes="symbol symbol-50 symbol-lg-100 font-size-h3 symbol-label font-weight-boldest" :src="get(detailsData, 'logo')" :name="get(detailsData, 'company_name')" :initial-letters="3"></cs-avatar>
                                </div>
                                <div class="flex-grow-1">
                                    <div class="mt-2">
                                        <div class="text-dark font-size-h5 font-weight-bold">
                                            Board President
                                        </div>
                                        <div class="d-flex flex-wrap my-2">
                                            <a href="#" class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                <span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                    <inline-svg src="media/svg/icons/General/User.svg" />
                                                </span>
                                                {{ get(detailsData, 'board_president.data.attributes.first_name') }}
                                                {{ get(detailsData, 'board_president.data.attributes.last_name') }}
                                            </a>
                                            <a href="#" class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                                <span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                    <inline-svg src="media/svg/icons/Communication/Mail-notification.svg" />
                                                </span>
                                                {{ get(detailsData, 'board_president.data.attributes.email', '-') }}
                                            </a>
                                        </div>
                                    </div>
                                    <!-- <div class="font-weight-bold text-dark-50 py-2 py-lg-2"></div> -->
                                </div>
                            </div>
                        </b-col>
                        <b-col md="6" lg="">
                            <div class="mt-2">
                                <div class="text-dark font-size-h5 font-weight-bold">
                                    Credit Criteria
                                </div>
                            </div>
                            <div class="font-weight-bold text-dark-50 py-2 py-lg-2">
                                <ul class="list-unstyled mb-0">
                                    <li class="d-flex justify-content-between mb-1">
                                        Maximum Loan to Value Ratio:
                                        <span class="font-weight-bolder text-dark">{{ detailsData.maximum_loan_ratio || '-' }}</span>
                                    </li>
                                    <li class="d-flex justify-content-between mb-1">
                                        Maximum Debt to Income Ratio:
                                        <span class="font-weight-bolder text-dark">{{ detailsData.maximum_debt_ratio || '-' }}</span>
                                    </li>
                                    <li class="d-flex justify-content-between mb-0">
                                        Minimum Credit Score:
                                        <span class="font-weight-bolder text-dark">{{ detailsData.minimum_credit_score || '-' }}</span>
                                    </li>
                                </ul>
                            </div>
                        </b-col>
                        <b-col md="6" lg="">
                            <div class="mt-2">
                                <div class="text-dark font-size-h5 font-weight-bold">
                                    Fees
                                </div>
                            </div>
                            <div class="font-weight-bold text-dark-50 py-2 py-lg-2">
                                <ul class="list-unstyled mb-0">
                                    <li class="d-flex justify-content-between mb-1">
                                        Application Fee:
                                        <span class="font-weight-bolder text-dark">{{moneyDollarForamter(detailsData.application_fee)}}</span>
                                    </li>
                                    <li class="d-flex justify-content-between mb-1" v-if="detailsData.background_check">
                                        Background Fee:
                                        <span class="font-weight-bolder text-dark">$125.00</span>
                                    </li>
                                </ul>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
                <b-nav class="nav-custom">
                    <router-link :to="{ name: 'cooperatives-view-details', cooperativeId }" v-slot="{ href, navigate, isActive }">
                        <b-nav-item :class="[$route.name == 'cooperatives-view-details' ? 'active' : '']" :href="href" @click="navigate">
                            <h4 class="nav-item__title mb-0">Cooperative Details</h4>
                        </b-nav-item>
                    </router-link>
                    <router-link :to="{ name: 'cooperatives-view-board-members', cooperativeId }" v-slot="{ href, navigate, isActive }">
                        <b-nav-item :class="[isActive && 'active']" :href="href" @click="navigate">
                            <h4 class="nav-item__title mb-0">Board Members</h4>
                        </b-nav-item>
                    </router-link>
                    <router-link :to="{ name: 'cooperatives-view-applicants', cooperativeId }" v-slot="{ href, navigate, isActive }">
                        <b-nav-item :class="[isActive && 'active']" :href="href" @click="navigate">
                            <h4 class="nav-item__title mb-0">Applicants</h4>
                        </b-nav-item>
                    </router-link>
                    <router-link :to="{ name: 'cooperatives-view-instructions', cooperativeId }" v-slot="{ href, navigate, isActive }">
                        <b-nav-item :class="[isActive && 'active']" :href="href" @click="navigate">
                            <h4 class="nav-item__title mb-0">Instructions</h4>
                        </b-nav-item>
                    </router-link>
                </b-nav>
                <transition name="fade-in">
                    <router-view v-if="detailsData" :details-data="detailsData" />
                </transition>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'CooperativesSingleLayout',
    components: {},
    data() {
        return {
            loading: false,
            detailsData: null,
            count: 0,
            mountCount: 0,
        };
    },
    mounted() {
        this.$root.$on('updateinfoBoardPr', (val) => {
            this.mountFunc()
        })
        this.mountFunc()

    },
    methods: {
        ...mapActions({
            getCooperative: 'cooperatives/show',
        }),
        mountFunc() {
            this.loading = true;
            this.getCooperative(this.cooperativeId)
                .then((data) => {
                    if (data.attributes.step_status < 3) return this.$router.push({ name: 'cooperatives-index' });
                    this.detailsData = data.attributes;
                    this.$root.$emit('changeTitle', this.detailsData.company_name);
                })
                .finally(() => (this.loading = false));
        },
        getScore() {
            const args = Array.prototype.slice.call(arguments);
            return args.reduce((acc, cur) => {
                const a = acc || 0;
                const b = cur || 0;
                return a + b;
            }, 0);
        },
    },
    computed: {
        ...mapGetters({
            isAuthenticated: 'auth/isAuthenticated',
            layoutConfig: 'config/layoutConfig',
        }),
        cooperativeId() {
            return this.$route.params.cooperativeId;
        },
        contentFluid() {
            return this.layoutConfig('content.width') === 'fluid';
        },
    },
};
</script>